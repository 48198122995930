import { API_URL } from '../config';
import { ISettings, IContent } from '../types';

export abstract class Api {
  public static apiUrl = API_URL;

  public static async getSettings(apiKey: string, widgetVersion: number): Promise<ISettings & { id: string; }> {
    const url = new URL(Api.apiUrl + '/api/widget/settings');
    url.searchParams.append('apiKey', apiKey);
    url.searchParams.append('widgetVersion', widgetVersion.toString());
    return await fetch(url.href, {
      method: 'GET',
    }).then(this.handleResponse);
  };

  public static async getContent(
    token: string,
    widgetVersion: number,
    sources?: { productIds?: string[]; collectionIds?: string[] },
    settings?: ISettings
  ): Promise<IContent> {
    const url = new URL(Api.apiUrl + '/api/widget/content');
    url.searchParams.append('apiKey', token);
    url.searchParams.append('widgetVersion', widgetVersion.toString());
    url.searchParams.append('settings', JSON.stringify(settings));
    if (sources?.productIds?.length) {
      url.searchParams.append('products', sources.productIds.join());
    }
    if (sources?.collectionIds?.length) {
      url.searchParams.append('collections', sources.collectionIds.join());
    }

    return await fetch(url.href, {
      method: 'GET',
    }).then(this.handleResponse);
  };

  private static async handleResponse(r: Response) {
    const { err, res } = await r.json();
    if (err) {
      throw err;
    }
    return res;
  };
}
