import { Carousel } from '../Layouts/Carousel';
import { Wall } from '../Layouts/Wall';
import { Mosaic } from '../Layouts/Mosaic';
import * as React from 'react';
import { PostViewer } from '../PostViewer';
import { StoreComponent } from '../../services/store';
import { IMedia } from '../../types';
import { Thumbnail } from '../Thumbnail';
import { style } from 'typestyle';
import { Visibility } from '../Visibility';
import { Helpers } from '../../services/helpers';
import { createPortal } from 'react-dom';

// Maximum number of visible elements for carousels on mobile
const MAX_MOBILE_CAROUSEL_ELEMS = 3;

interface IProps {
  root: HTMLElement;
  startDate: Date;
  postViewerPortalId?: string;
}

class Widget extends StoreComponent<IProps> {
  private hasloaded = false;
  private postViewerPortal?: HTMLElement | null;

  public render() {
    if (!this.context.data) {
      this.widgetLoadingFailed();
      return null;
    }

    this.widgetLoaded();

    const currentType = this.context.data.settings.type || 'wall';

    let nbElems = this.context.data.settings.content_size;
    if (this.context.isMobile) {
      // set maximum of elements
      nbElems = Math.min(this.context.data.settings.content_size, MAX_MOBILE_CAROUSEL_ELEMS);
    }

    if (this.props.postViewerPortalId) {
      this.postViewerPortal = document.querySelector(`#${this.props.postViewerPortalId}`);
    }

    return (
      <Visibility>
        {(ref) => (
          <div className={widgetClass} ref={ref}>
            <div className={`${this.context.config.classPrefix}-container`}>
              {
                {
                  carousel: React.createElement(Carousel, {
                    items: this.context.data.content.medias.map((m, i) => this.mediaRender(m, i)),
                    nbElems,
                  }),
                  mosaic: React.createElement(Mosaic),
                  wall: React.createElement(Wall, {
                    forceColumns: this.context.data.settings.forceColumns,
                  }),
                }[currentType]
              }
            </div>

            <div>{this.postViewerPortal && createPortal(<PostViewer />, this.postViewerPortal)}</div>
          </div>
        )}
      </Visibility>
    );
  }

  private widgetLoaded() {
    if (this.hasloaded) {
      return;
    }
    let elapsedSeconds: number | undefined;
    if (this.props.startDate) {
      const now = new Date();
      elapsedSeconds = (now.getTime() - this.props.startDate.getTime()) / 1000;
    }
    this.hasloaded = true;
    this.context.triggerEvent(
      'widgetLoaded',
      {
        elapsedSeconds,
        posts: this.context.data.content.medias,
        layout: this.context.data.settings.type
      },
      this.context.data.id
    );
  }

  private widgetLoadingFailed() {
    if (this.hasloaded) {
      return;
    }
    this.hasloaded = true;
    this.context.triggerEvent(
      'widgetLoadingFailed',
      {},
      this.context.data.id
    );
  }

  private mediaRender(media: IMedia, position: number): JSX.Element {
    return <Thumbnail media={media} position={position} />;
  }

  public componentDidMount() {
    this.handleElementMutation(this.props.root);
  }

  public handleMobile(width: number) {
    this.context.setStoreState({
      isMobile: this.context.forceMobile || Helpers.isMobile(width),
    });
  }

  private handleElementMutation(element: HTMLElement | string): void {
    const htmlElement: HTMLElement | null = typeof element === 'string' ? document.querySelector(element) : element;

    if (htmlElement === null) {
      return;
    }

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const width = Math.floor(entry.contentRect.width);
        this.handleMobile(width);
      }
    });

    resizeObserver.observe(htmlElement);
  }
}

// Style to counter potential parent style
const widgetClass = style({
  all: 'initial',
  $nest: {
    '*': {
      boxSizing: 'initial',
    },
  },
});

export { Widget };
