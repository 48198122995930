import * as React from 'react';
import { classes, style, stylesheet } from 'typestyle';
import { Debug } from '../../../../debug';
import { StoreContext } from '../../../../services/store';
import { IMedia, IProduct } from '../../../../types';
import { onKeyDownCallback, onKeyDownEventCallback } from '../../../../utils/onKeyDown';

interface Props {
  product: IProduct;
  parentMedia: IMedia;
  isVisible: boolean;
}

const ROW_SIZE = 200;
const MIN_HEIGHT = 100;

export const Product = React.forwardRef<HTMLDivElement, Props>(({ product, parentMedia, isVisible }, ref) => {
  const context = React.useContext(StoreContext);
  const anchorRef = React.createRef<HTMLAnchorElement>();

  const title = [product.title, product.variant_name].filter((v) => v).join(' - ');

  const shopButtonClicked = React.useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent> | React.KeyboardEvent<HTMLAnchorElement>) => {
      const { link, id } = product;
      if (context.post) {
        context.triggerEvent(
          'shopThisLook',
          {
            post: context.post,
            originalEvent: e.nativeEvent,
            product: id,
          },
          context.data.id
        );
      }

      if (context.config.shopThisLookHandler) {
        context.config.shopThisLookHandler(
          {
            media: parentMedia,
            product,
          },
          link
        );
      } else {
        window.open(link, '_self');
      }
    },
    [product, parentMedia, context.post]
  );

  const imageLink = isVisible ? product.image_link : undefined;

  return (
    <div ref={ref} style={productStyle(context.isMobile)} className={`${context.config.classPrefix}-postviewer-details-product`}>
      <div
        style={productImg(imageLink, context.isMobile)}
        className={`${context.config.classPrefix}-postviewer-details-product-image`}
        onClick={() => anchorRef.current?.click()}
        onKeyDown={(e) => onKeyDownCallback(e, () => anchorRef.current?.click())}
        tabIndex={0}
        role='link'
        aria-label={`go to the ${title}'s product page`}
      >
        {context.config.getCustomProductButtons
          ? Object.entries(context.config.getCustomProductButtons(React, product, parentMedia)).map(
              /** We're mapping on the key-value pairs returned by the productCustomButtons function
               *  to display the buttons with the correct implied position */
              ([position, Button]) => (
                <div
                  className={customButtonPosition[position as keyof typeof customButtonPosition]}
                  key={position}
                  onClick={(e) => e.stopPropagation()}
                >
                  <Button onClick={() => {}} />
                </div>
              )
            )
          : ''}
      </div>

      <div className={classes(productInfosStyle, `${context.config.classPrefix}-postviewer-details-product-info`)}>
        <a
          ref={anchorRef}
          onClick={(e) => shopButtonClicked(e)}
          onKeyDown={(e) =>
            onKeyDownEventCallback(e, () => {
              shopButtonClicked(e);
            })
          }
          tabIndex={0}
          role='link'
          aria-label={`go to the ${title}'s product page`}
          className={classes(titleStyle, `${context.config.classPrefix}-postviewer-details-product-title`)}
          target='_self'
        >
          {title}
        </a>
        {product.price !== 0 && product.price ? <p style={priceStyle}>{`${product.price} ${currencyToSymbol(product.currency)}`}</p> : null}
      </div>
    </div>
  );
});

function currencyToSymbol(currency?: string): string {
  if (!currency) {
    return '';
  }
  // Amount not returned. Used only to convert with the appropriate currency.
  try {
    const emptyAmount = 0;
    const currencySymbol = emptyAmount
      .toLocaleString('en', {
        style: 'currency',
        currency,
      })
      .replace('0.00', '')
      .trim();
    return currencySymbol;
  } catch (error) {
    Debug.log('Invalid currency :', { error });
    return '';
  }
}

const productImg = (url?: string, isMobile: boolean = false): React.CSSProperties => ({
  display: 'inline-table',
  backgroundImage: url && `url('${url}')`,
  backgroundSize: 'contain',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  minHeight: isMobile ? MIN_HEIGHT : ROW_SIZE - 35,
  maxHeight: ROW_SIZE,
  height: 'auto',
  cursor: 'pointer',
  position: 'relative',
});

const productInfosStyle = style({
  display: 'flex',
  textAlign: 'center',
  alignItems: 'center',
  flexDirection: 'column',
});

const titleStyle = style({
  wordBreak: 'break-word',
  whiteSpace: 'break-spaces',
  display: 'flex',
  lineHeight: '14px',
  overflowY: 'hidden',
  margin: 0,
  marginTop: 18,
  flexGrow: 1,
  alignItems: 'flex-start',
  cursor: 'pointer',
  height: '43px',
});

const customButtonPosition = stylesheet({
  topRight: {
    position: 'absolute',
    top: 0,
    right: 0,
  },

  topLeft: {
    position: 'absolute',
    top: 0,
    left: 0,
  },

  bottomRight: {
    position: 'absolute',
    bottom: 0,
    right: 0,
  },

  bottomLeft: {
    position: 'absolute',
    bottom: 0,
    left: 0,
  },
});

const priceStyle: React.CSSProperties = {
  lineHeight: '26px',
  margin: 0,
};

const productStyle = (isMobile: boolean = false): React.CSSProperties => ({
  padding: 10,
  display: 'flex',
  flexDirection: 'column',
  height: 'fit-content',
  minHeight: MIN_HEIGHT,
  minWidth: isMobile ? MIN_HEIGHT : 'auto',
  // This font come from google fonts. See addFonts() in src/adalongWidget.tsx
  fontFamily: "'Be Vietnam Pro', sans-serif",
  fontWeight: 300,
  fontSize: isMobile ? 9 : 10,
  letterSpacing: '0.3px',
  color: '#121314',
});
