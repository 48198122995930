import {
  CarouselScrollEvent,
  EventCallback,
  EventCallbacks,
  EventMap,
  EventMapKeys,
  IWidgetEvents,
  MediaPosition,
  PostEvent,
  PostNavigationEvent,
  ProductEvent,
} from '../types';
import { Debug } from '../debug';
import { GACustomMap, GAnalytics } from './analytics';
import { GA_KEY } from '../config';

export class WidgetEvents implements IWidgetEvents {
  protected destroyed: boolean = false;

  private eventCallbacks: { [key in EventMapKeys]?: EventCallbacks<EventMap[key]> } = {};

  private GA = GA_KEY ? new GAnalytics(GA_KEY) : undefined;

  // Event types that are not sent to AdAlong Analytics
  private readonly ignoredEventsAnalytics: Set<EventMapKeys> = new Set([
    'thumbnailLoaded',
    'minContentReached',
    'postClosed',
  ]);

  public onEvent<K extends EventMapKeys>(eventName: K, callback: EventCallback<EventMap[K]>) {
    if (this.destroyed) {
      return;
    }
    Debug.try(() => {
      const callbacks = this.getCallbacks(eventName);
      if (callbacks.indexOf(callback) === -1) {
        callbacks.push(callback);
      }
      this.eventCallbacks[eventName] = callbacks as any; // fixme
    });
  }

  public offEvent<K extends EventMapKeys>(eventName: K, callback?: EventCallback<EventMap[K]>) {
    Debug.try(() => {
      let callbacks = this.getCallbacks(eventName);
      if (callback) {
        const i = callbacks.indexOf(callback);
        if (i > -1) {
          callbacks.splice(i, 1);
        }
      } else {
        callbacks = [];
      }
      this.eventCallbacks[eventName] = callbacks as any; // fixme
    });
  }

  public hasSubscribed(eventName: EventMapKeys): boolean {
    return !!this.eventCallbacks[eventName]?.length;
  }

  /**
   * Called when the widget is destroyed
   * Remove all the events
   */
  protected destroy() {
    this.eventCallbacks = {};
  }

  protected triggerEvent<K extends EventMapKeys>(
    eventName: K,
    event: EventMap[K],
    widgetId?: string,
  ) {
    if (this.destroyed) {
      return;
    }
    const callbacks = this.getCallbacks(eventName);
    callbacks.forEach((callback) => callback(event));

    if (widgetId && !this.shouldIgnoreEvent(eventName) && this.GA) {
      const data = this.formatEventForAnalytics(eventName, event, widgetId);
      this.GA.trackEvent({ eventName, data });
    }
  }

  private getCallbacks<K extends EventMapKeys>(eventName: K): EventCallbacks<EventMap[K]> {
    let callbacks = this.eventCallbacks[eventName];
    if (!callbacks) {
      callbacks = this.eventCallbacks[eventName] = [];
    }
    return callbacks;
  }

  private shouldIgnoreEvent(eventName: EventMapKeys): boolean {
    return this.ignoredEventsAnalytics.has(eventName);
  }

  private formatEventForAnalytics<K extends EventMapKeys>(
    eventName: K,
    event: EventMap[K],
    widgetId: string,
  ): GACustomMap {
    switch (eventName) {
      case 'thumbnailUnavailable':
      case 'thumbnailHover':
      case 'originalPostOpened':
      case 'postOpened':
      case 'videoPlayed':
        return {
          event_widget_id: widgetId,
          event_post_id: (event as PostEvent).post.id,
        };
      case 'thumbnailClicked':
        return {
          event_widget_id: widgetId,
          event_post_id: (event as PostEvent & MediaPosition).post.id,
          event_position: (event as PostEvent & MediaPosition).position,
        }
      case 'shopThisLook':
        return {
          event_widget_id: widgetId,
          event_post_id: (event as PostEvent).post.id,
          event_product_id: (event as ProductEvent).product,
        };
      case 'postNavigation':
        return {
          event_widget_id: widgetId,
          event_direction: (event as PostNavigationEvent).direction,
        }
      case 'carouselArrowClicked':
      case 'carouselNativeScroll':
        return {
          event_widget_id: widgetId,
          event_direction: (event as CarouselScrollEvent).direction,
          event_position: (event as CarouselScrollEvent).position,
        }
      default:
        return {
          event_widget_id: widgetId,
        };
    }
  }
}
