import * as React from 'react';
import { useEffect, useState, useRef } from 'react';
import { StoreContext } from '../../services/store';
import { RightArrowIcon } from '../Icons/RightArrow';
import { LeftArrowIcon } from '../Icons/LeftArrow';
import { Content } from './components/Content';
import { Details } from './components/Details';
import { CloseIcon } from '../Icons/Close';
import { BackIcon } from '../Icons/Back';
import { Helpers } from '../../services/helpers';
import { IMedia, Direction } from '../../types';
import { onKeyDownCallback } from '../../utils/onKeyDown';

function PostViewer() {
  const context = React.useContext(StoreContext);
  const [image, setImage] = useState<string>();
  const minHeight = 500;
  const minWidth = 500;
  const maxHeight = 700;
  let keyDownListener: (e: KeyboardEvent) => void;
  let popStateListener: (e: PopStateEvent) => void;
  const [imageWidth, setImageWidth] = useState<number>(935);
  const [imageHeight, setImageHeight] = useState<number>(500);
  const [ratio, setRatio] = useState<number>(1);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setImage(context?.post?.image);
  }, [context?.post?.image]);

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.focus();
    }
  }, [image]);

  useEffect(() => {
    if (!image) {
      return;
    }
    const img = new Image();
    img.src = image;
    img.onload = () => {
      setImageWidth(img.width);
      setImageHeight(img.height);
      const r = img.width / img.height;
      setRatio(r);
    };
  }, [image]);

  useEffect(() => {
    keyDownListener = onKeyDown;
    popStateListener = popState;
    document.addEventListener('keydown', keyDownListener, false);
    window.addEventListener('popstate', popStateListener);
    return () => {
      if (keyDownListener) {
        document.removeEventListener('keydown', keyDownListener, false);
      }
      if (popStateListener) {
        window.removeEventListener('popstate', popStateListener);
      }
    };
  }, []);

  useEffect(() => {
    // @ts-ignore
    const listener = ({detail}) => arrowEvent(detail)
    if(context?.post) {
      // @ts-ignore
      window.addEventListener('adalongWidget_changePost', listener)
    }
    return () => {
      // @ts-ignore
      window.removeEventListener('adalongWidget_changePost', listener)
    }
  }, [context?.post])

  const desktopStyle: React.CSSProperties = {
    position: 'fixed',
    padding: 40,
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    display: 'flex',
    backgroundColor: '#21212159',
    zIndex: 999,
    overflow: 'auto',
  };

  const contentStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
  };

  const mobileStyle: React.CSSProperties = {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: 'white',
    zIndex: 999,
    overflowY: 'scroll',
    overflowX: 'hidden',
  };

  const desktopPostContainerStyle: React.CSSProperties = {
    backgroundColor: 'white',
    position: 'relative',
    width: context.data.settings.shop_this_look ? 935 : imageWidth,
    maxWidth: context.data.settings.shop_this_look ? 935 : ratio * maxHeight,
    padding: context.data.settings.shop_this_look ? 0 : '25px 10px 0 10px',
    margin: 'auto',
    alignItems: 'center',
    height: context.data.settings.shop_this_look ? '50vh' : imageHeight,
    maxHeight,
    minHeight,
    minWidth,
  };

  const mobilePostContainerStyle: React.CSSProperties = {
    backgroundColor: 'white',
    position: 'relative',
    width: '100%',
    margin: 'auto',
  };

  const buttonStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    height: '100%',
  };

  const closeButtonStyle: React.CSSProperties = {
    position: 'absolute',
    top: 8,
    right: 8,
    cursor: 'pointer',
  };

  const backButtonStyle: React.CSSProperties = {
    padding: '14px 9px',
    position: 'absolute',
    mixBlendMode: 'difference',
    color: 'white',
    zIndex: 3,
  };

  const svgBackStyle: React.CSSProperties = {
    mixBlendMode: 'exclusion',
    filter: 'invert(1)',
    color: '#000',
    height: 35,
    width: 35,
  };

  return !context.isMobile ? renderDesktop() : renderMobile();

  function renderDesktop() {
    if (!context.post) {
      return null;
    }
    const buttonDistance = 25;
    const buttonColor = 'white';
    const disabledButtonColor = '#ffffff32';
    const firstPost = context.post.postIndex === 0;
    const lastPost = context.post.postIndex === context.data.content.medias.length - 1;

    return (
      <div style={desktopStyle} onClick={close}>
        <article
          style={desktopPostContainerStyle}
          onClick={(e) => e.stopPropagation()}
          onKeyDown={(e) => onKeyDownCallback(e, () => e.stopPropagation())}
          className={`${context.config.classPrefix}-postviewer`}
        >
          <div style={{ ...buttonStyle, left: -buttonDistance }}>
            <LeftArrowIcon
              className={`${context.config.classPrefix}-postviewer-arrow-left`}
              style={{ color: !firstPost ? buttonColor : disabledButtonColor }}
              onClick={() => arrowEvent('left')}
              onKeyDown={(e) => onKeyDownCallback(e, () => arrowEvent('left'))}
              tabIndex={0}
              role='button'
              aria-label='previous'
            />
          </div>
          <div style={contentStyle} className={`${context.config.classPrefix}-postviewer-post`}>
            <Content ref={contentRef} />
            {context.data?.settings?.shop_this_look && <Details />}
          </div>
          <div style={{ ...buttonStyle, right: -buttonDistance }}>
            <RightArrowIcon
              className={`${context.config.classPrefix}-postviewer-arrow-right`}
              style={{ color: !lastPost ? buttonColor : disabledButtonColor }}
              onClick={() => arrowEvent('right')}
              onKeyDown={(e) => onKeyDownCallback(e, () => arrowEvent('right'))}
              tabIndex={0}
              role='button'
              aria-label='next'
            />
          </div>
          <CloseIcon
            className={`${context.config.classPrefix}-postviewer-close`}
            style={closeButtonStyle}
            size={11}
            onClick={close}
            onKeyDown={(e) => onKeyDownCallback(e, close)}
            tabIndex={0}
            role='button'
            aria-label='close the postviewer'
          />
        </article>
      </div>
    );
  }

  function renderMobile() {
    if (!context.post) {
      return null;
    }
    return (
      <div style={mobileStyle}>
        <article style={mobilePostContainerStyle}>
          <div style={backButtonStyle}>
            <BackIcon
              onClick={close}
              style={svgBackStyle}
              role='button'
              aria-label='close the postviewer'
            />
          </div>
          <Content />
          <Details />
        </article>
      </div>
    );
  }

  function popState(event: PopStateEvent) {
    const { state } = event;
    if (!state || typeof state !== 'object' || !('widgetPost' in state)) {
      if (context.post) {
        context.triggerEvent(
          'postClosed',
          { post: context.post },
          context.data.id
        );
      }
      context.setStoreState({ post: null });
    } else {
      context.triggerEvent(
        'postOpened',
        { post: state.widgetPost },
        context.data.id
      );
      context.setStoreState({ post: state.widgetPost });
    }
  }

  function onKeyDown(e: KeyboardEvent) {
    if (e.key === 'ArrowRight') {
      arrowEvent('right');
    } else if (e.key === 'ArrowLeft') {
      arrowEvent('left');
    } else if (e.key === 'Escape') {
      close();
    }
  }

  function arrowEvent(dir: Direction): void {
    if (!context.post) {
      return;
    }
    context.triggerEvent(
      'postNavigation',
      { direction: dir },
      context.data.id
    );
    const { postIndex } = context.post;
    const { medias } = context.data.content;
    let post: IMedia | undefined;

    if (dir === 'left' && postIndex > 0) {
      post = medias[postIndex - 1];
      context.setStoreState({ post });
    } else if (dir === 'right' && postIndex < medias.length - 1) {
      post = medias[postIndex + 1];
      context.setStoreState({ post });
    }

    if (post) {
      context.triggerEvent(
        'postOpened',
        { post },
        context.data.id
      );
      Helpers.replacePostInHistory(post);
    }
  }

  function close() {
    if (context.post) {
      context.triggerEvent(
        'postClosed',
        { post: context.post },
        context.data.id
      );
    }
    context.setStoreState(() => ({ post: null }));
    history.replaceState(null, '', null);
  }
}

export { PostViewer };
