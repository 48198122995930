import * as React from 'react';
import { Thumbnail } from '../../Thumbnail';
import { Helpers } from '../../../services/helpers';
import { StoreContext } from '../../../services/store';
import { useEffect, useRef, useState } from 'react';
import { IMedia } from '../../../types';

interface Props {
  forceColumns?: number;
}

const Wall = ({ forceColumns }: Props) => {
  const context = React.useContext(StoreContext);
  const containerRef = useRef<HTMLDivElement>(null);
  const defaultColumnsNumber = context.isMobile ? 2 : 3;

  // Function to calculate dynamically the number of media per row, depending on screen size
  const columnsNumber = (): number => {
    const settings = context?.data?.settings;
    const contentSize = Number(settings?.content_size) ?? undefined;
    const contentsPerRow = forceColumns || contentSize || defaultColumnsNumber;
    const minWidth = 125;

    if (forceColumns) {
      return forceColumns;
    }
    if (!context?.isMobile) {
      return contentsPerRow;
    }
    if (!containerRef.current) {
      return defaultColumnsNumber;
    }

    const calculatedColumns = Math.floor(containerRef.current.clientWidth / minWidth) || 1;
    return containerRef.current.clientWidth / contentsPerRow > minWidth ? contentsPerRow : calculatedColumns;
  };

  const [numberOfColumns, setNumberOfColumns] = useState<number>(columnsNumber());
  // Call columnsNumber and change numberOfColumns accordingly at each re-render, included screen resize
  useEffect(() => {
    setNumberOfColumns(columnsNumber());
  });
  const [medias, setMedias] = useState<IMedia[]>(context?.data?.content?.medias ?? []);
  // Update media to show if context change
  useEffect(() => {
    setMedias(context?.data?.content?.medias);
  }, [context]);

  const renderRows = () => {
    let thumbStyle = { ...thumbnailStyle };
    const rStyle = { ...rowStyle };
    thumbStyle.width = `${100 / numberOfColumns}%`;
    const numberOfRow: number = Math.ceil(medias?.length / numberOfColumns) ?? 1;
    const rows: any[][] = Helpers.createArray(numberOfRow).map(() => []);
    medias.forEach((media, i: number) => {
      rows[Math.floor(i / numberOfColumns)].push(
        <div className={`${context?.config.classPrefix}-item`} style={thumbStyle} key={i}>
          <Thumbnail media={media} position={i} />
        </div>
      );
    });
    const lastRowContents = medias?.length % numberOfColumns;
    let rest = -lastRowContents + numberOfColumns;
    if (!lastRowContents) {
      rest = 0;
    }
    while (rest-- > 0) {
      rows[rows?.length - 1].push(
        <div className={`${context?.config.classPrefix}-item`} style={{ ...thumbStyle }} key={`empty-${rest}`}>
          <div style={emptyThumnailStyle} />
        </div>
      );
    }
    return rows.map((content, i) => (
      <React.Fragment key={i}>
        <div className={`${context?.config.classPrefix}-row`} style={rStyle}>
          {content}
        </div>
        <br />
      </React.Fragment>
    ));
  };

  return (
    <div className={`${context?.config.classPrefix}-layout-wall`} style={rowContainerStyle} ref={containerRef}>
      {renderRows()}
    </div>
  );
};

const rowStyle: React.CSSProperties = {
  display: 'inline-block',
  marginTop: -4,
  width: '100%',
};

const rowContainerStyle: React.CSSProperties = {
  textAlign: 'center',
};

const emptyThumnailStyle: React.CSSProperties = {
  paddingBottom: '100%',
};

const thumbnailStyle: React.CSSProperties = {
  display: 'inline-block',
};

export { Wall };
