import { IProduct } from '../../../../types';

// Get product information and limit the number of
// results depending on max products to be displayed
export const filterExactOrSimilarProducts = (
  maxExactProducts: number | null,
  maxSimilarProducts: number | null,
  products?: IProduct[],
  exactProductIds?: string[],
  similarProductIds?: string[]
): { exactProducts: IProduct[]; similarProducts: IProduct[] } => {
  const exactProducts: IProduct[] = [];
  const similarProducts: IProduct[] = [];

  if (products && exactProductIds) {
    const limitExact = maxExactProducts ?? 100; // to avoid overcharge
    exactProducts.push(...products.filter((product) => exactProductIds.includes(product.id)).slice(0, limitExact));
  }

  if (products && similarProductIds) {
    const limitSimilar = maxSimilarProducts ?? 100; // to avoid overcharge
    similarProducts.push(...products.filter((product) => similarProductIds.includes(product.id)).slice(0, limitSimilar));
  }

  return { exactProducts, similarProducts };
};
